<template>
  <div>
    <el-dialog
      :title="title"
      :visible="sexMixShow"
      width="800px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-sex">
        <p class="batch-p">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-radio-group
            v-model="comVal"
            size="small"
            :disabled="!checkedDatas.length"
            @change="(val) => batchChange('genders', val)"
          >
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button label="M">男</el-radio-button>
            <el-radio-button label="F">女</el-radio-button>
          </el-radio-group>
        </p>
        <el-checkbox-group
          v-model="checkedDatas"
          @change="
            (val) => handleCheckedDatasChange('genders', sexMsgList, val)
          "
        >
          <el-checkbox v-for="item in dataList" :label="item.id" :key="item.id">
            <el-tooltip v-if="item.name" :content="item.name">
              <span class="mixName">{{ item.name }}</span></el-tooltip
            >
            <el-radio-group v-model="item.genders" size="small">
              <el-radio-button label="all">全部</el-radio-button>
              <el-radio-button label="M">男</el-radio-button>
              <el-radio-button label="F">女</el-radio-button>
            </el-radio-group>
          </el-checkbox>
        </el-checkbox-group>
        <!-- <el-row :gutter="10" v-for="item in dataList" :key="item.id">
                    <el-col :span="16" style="line-height:40px">
                        <span class="mixName">{{item.name}}</span>
                    </el-col>
                    <el-col :span="8">
                        <el-radio-group v-model="item.genders" size="small">
                            <el-radio-button label="all">全部</el-radio-button>
                            <el-radio-button label="M">男</el-radio-button>
                            <el-radio-button label="F">女</el-radio-button>
                        </el-radio-group>
                    </el-col>
                </el-row> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mixBatchOperate from "@/views/adManagement/assets/js/mixBatchOperate";
export default {
  mixins: [mixBatchOperate],
  props: {
    sexMixShow: {
      type: Boolean,
      default: () => false,
    },
    sexMsgList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "性别",
    },
  },
  data() {
    return {
      initData: this.$store.getters.initData,
      dataList: JSON.parse(JSON.stringify(this.sexMsgList)),
    };
  },
  methods: {
    close() {
      this.backToInit(this.sexMsgList);
      this.$emit("update:sexMixShow", false);
    },
    openInit() {},
    sure() {
      this.$emit("setMixMsg", "sex", this.dataList);
      this.close();
    },
  },
  watch: {
    sexMsgList: {
      handler() {
        if (this.sexMsgList) {
          this.dataList = JSON.parse(JSON.stringify(this.sexMsgList));
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scope>
.localsList {
  .el-row {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.mix-wrap-sex {
  .batch-p {
    display: flex;
    align-items: center;
    .el-radio-group {
      margin-left: 10px;
    }
  }
  .el-checkbox-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .el-checkbox {
      width: 95%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mixName {
      width: 550px;
    }
  }
}
</style>