<template>
    <div>
        <el-form v-model="adSetInfo" :label-width="labelWidth">
            <el-form-item label="投放优化目标：">
                <el-select v-model="adSetInfo.optimization_goal" placeholder="请选择"  @change="setTarget" v-if="initData.ad">
                    <el-option :label="item.desc" :value="item.key" v-for="(item,index) in initData.ad.AdSetOptimizationGoal" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="投放策略：">
                <span class="celue">
                    <span>{{adSetInfo.bid_strategy=='LOWEST_COST_WITHOUT_CAP'?'自动竞价':adSetInfo.bid_strategy=='TARGET_COST'?'目标费用':''}}</span>
                    <el-tooltip class="item" effect="light" content="使用出价上限竞价策略，Facebook 会尽可能获得最多展示次数，且在每次竞拍中，竞价金额不会超过$2，按展示次数计费" placement="top-start">
                    <i class="el-icon-warning-outline" style="margin:0 10px"></i>
                    </el-tooltip>
                </span>
                <el-button type="text" @click="setStrategyShow = true" v-if="!setStrategyShow">调整</el-button>
                <el-button type="text" @click="setStrategyShow = false" v-if="setStrategyShow">收起</el-button>
            </el-form-item>
            <div v-if="setStrategyShow">
                <el-form-item label="广告系列竞价策略：" prop="resource">
                    <el-radio-group v-model="adSetInfo.bid_strategy">
                            <el-radio :label="item.key" v-for="(item,i) in initData.ad.CampaignBidStrategy" :key="i">{{item.desc}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="费用控制额：" prop="name" v-if="adSetInfo.bid_strategy != 'LOWEST_COST_WITHOUT_CAP'">
                    <el-input v-model="adSetInfo.bid_amount" style="width：200px">
                        <el-button slot="append">{{currencyName}}</el-button>
                    </el-input>
                    <span style="margin-left:15px">单次转化量费用</span>
                </el-form-item>
                <el-form-item label="转化时间窗：" prop="region">
                    <el-select v-model="adSetInfo.attribution_spec">
                        <el-option :label="times.desc" :value="times.key" v-for="(times) in initData.ad.AdSetAttributionSpec" :key="times.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="计费方式：" prop="resource">
                    <el-radio-group v-model="adSetInfo.billing_event">
                        <el-radio :label="types.key" v-for="(types) in targetTypeList" :key="types.key">{{types.desc}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['label-width'],
        data() {
            return {
                setStrategyShow:false,
                initData:this.$store.getters.initData,
                targetTypeList:[],
                adSetInfo:{
                    optimization_goal:'',
                    bid_strategy:'LOWEST_COST_WITHOUT_CAP',
                    bid_amount:'',
                    attribution_spec:'',
                    billing_event:''
                },
                initData:this.$store.getters.initData
            }
        },
        computed:{
            currencyName(){
                return this.$store.getters.currentUiUnit.name;
            }
        },
        methods: {
            // 选择优化目标
            setTarget(v){
                let list = this.initData.ad.AdSetOptimizationGoal.filter(k=>k.key == v)
                this.targetTypeList = list[0].billingEvents;
                // console.log(this.targetTypeList);
            },
        },
    }
</script>
<style lang="scss" scope>
    
</style>