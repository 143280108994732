<template>
    <div>
        <el-form v-model="sexInfo"  :label-width="labelWidth">
            <el-form-item label="性别：" prop="sex">
                <el-radio-group v-model="sexInfo.genders" size="small" @change="setSex">
                    <el-radio-button label="all">全部</el-radio-button>
                    <el-radio-button label="M">男</el-radio-button>
                    <el-radio-button label="F">女</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['label-width','sexMsg'],
        data() {
            return {
                sexInfo:{
                    genders: this.sexMsg
                }
            }
        },
        methods: {
            setSex(v){
                this.$emit('getSex',v)
            }
        },
        watch:{
            sexMsg:{
                handler(){
                    console.log(this.sexMsg);
                    this.sexInfo.genders = this.sexMsg
                }
            }
        }
    }
</script>
<style lang="scss" scope>
    
</style>